import * as React from "react";
import { Helmet } from "react-helmet";
import cn from "classnames";
import { Layout } from "../components/legacy";
import LOGO from "../../img/legacy/logo-white.svg";

import style from "./login.module.css";
import { Config } from "../constants";

export default class ForgotPage extends React.Component {
  state = {
    email: "",
    resetSuccess: false,
  };

  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value.trim(),
    });
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email } = this.state;
    this.setState({ resetSuccess: false });

    if (email) {
      fetch(`${Config.apiServer}/api/users/resetPassword`, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      this.setState({ resetSuccess: true });

      window.setTimeout(() => {
        window.location.href = "/login";
      }, 4000);
    }
  };

  render() {
    const { email } = this.state;
    return (
      <>
        <Layout>
          <Helmet>
            <title>Shipamax | Forgot Password</title>
            <meta name="description" content="Recover your Shipamax account." />
          </Helmet>

          {/* Banner */}
          <div className={style.root}>
            <div className={style.login__banner}>
              <div className="container">
                <div className={`row ${style.customRow}`}>
                  <div className="col s12">
                    <div className={style.login__bannerContent}>
                      <h1 className={style.login__heading}>
                        <img
                          className={cn(style.logo, style.logoLeft)}
                          src={LOGO}
                          alt="Logo"
                        />
                      </h1>
                      <form
                        className={cn(style.loginForm, style.resetForm)}
                        onSubmit={this.handleSubmit}
                      >
                        <div className={style.form__resetInstructions}>
                          Please enter the email address that you normally use
                          to login.
                        </div>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={this.handleInputChange}
                          placeholder="Email"
                          className={cn(style.formInput)}
                        />
                        <div
                          className={cn(style.form__loginError, {
                            [style.active]: this.state.resetSuccess,
                          })}
                        >
                          Password reset instructions have been sent to you by
                          email.
                          <br />
                          <br />
                          You will be redirected to the login page shortly.
                        </div>
                        <div className={style.form__buttons}>
                          <button type="submit" className={style.formSubmit}>
                            Reset Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}
